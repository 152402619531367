import React from 'react'
import ReactMarkdown from "react-markdown"
// import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql, Link } from 'gatsby'
// import BackgroundImage from "gatsby-background-image"
import TopMenu from '../components/topmenu'
import SEO from "../components/seo"
import Footer from "../components/footer"
// import ButtonFrame from "../components/buttonFrame"
import slugHandler from '../api/slugHandlerBrowser'
// import frame from "../../content/assets/frame.svg"

const AlbumsTemplate = ({ data, pageContext }) => {
    // console.log("AlbumsTemplate: ", data);
  const { frontmatter: content } = data.content
  const slug = content.slug;
  const albums = data.albums.nodes;
  return (
    <section className="tar-page tar-albums">
      <SEO
        title="Home"
        description={content.description}
        keywords={[`photo`, `photographer`, `Taranukhina`, `Elena`,`valokuvaaja`,`Tampere`]}
      />
      <TopMenu smallLogo={true} lang={content.language} languages={pageContext.languages} slug={slug} translation={pageContext.translation}></TopMenu>
      <div className="container-1200">
        <div className="tar-section tar-albums-in">
          <h1 className="tar-albums-in-title">{content.title}</h1>
          <div className="tar-title-line"></div>
          <div className="tar-article tar-albums-in-text ffrw">
            <ReactMarkdown source={content.content} linkTarget="_blank"/>
            {/* <a href="/" className="tar-link">link text</a>
            <br /><br />
            <button className="tar-button">Learn more</button>
            <ButtonFrame text="Learn more"></ButtonFrame> */}
          </div>
          <div className="tar-albums-list">
            {
              albums.map((album)=>{
                const path = slugHandler(
                  content.language,
                  album.slug
                )
                
                return (
                  <Link to={path} key={album.id} className="tar-album-list-item">
                      <div style={{"backgroundImage": `url(${album.frontmatter.image})`}} className="tar-album-list-item-bg">
                        <div className="tar-album-overlay"></div>
                        <span className="tar-button-frame top-right-v"></span>
                        <span className="tar-button-frame top-left-v"></span>
                        <span className="tar-button-frame bottom-left-v"></span>
                        <span className="tar-button-frame bottom-right-v"></span>
                        <span className="tar-button-frame top-right-h"></span>
                        <span className="tar-button-frame top-left-h"></span>
                        <span className="tar-button-frame bottom-left-h"></span>
                        <span className="tar-button-frame bottom-right-h"></span>
                      </div>
                      {/* <BackgroundImage fluid={album.frontmatter.image.childImageSharp.fluid} className="tar-album-list-photo">
                        <div className="tar-album-overlay"></div>
                      </BackgroundImage> */}
                      <div className="tar-album-list-discription">
                        {/* <div className="tar-album-list-discription-date mb1">{album.frontmatter.date}</div> */}
                        <div className="tar-title-line mb1"></div>
                        <h2 className="tar-album-list-discription-title">{album.frontmatter.title}</h2>
                      </div>
                    </Link>
                )
              })
            }
          </div>
        </div>
      </div>
      <Footer lang={content.language} translation={pageContext.translation}></Footer>
    </section>
  )
}

export default AlbumsTemplate

export const indexQuery = graphql`
  query AlbumsById($id: String!, $language: String!) {
    content: mdx(
      id: { eq: $id },
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/albums" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        slug
        language
        title
        description
        content
      }
    }
    albums: allMdx(filter: {frontmatter: {templateKey: {eq: "album"}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        slug
        id
        frontmatter {
          date(formatString: "DD MMM YYYY")
          description
          title
          image
        }
      }
    }
    
  }
`